.dropzone {
    display: flex;
    border: 1px solid lightgray !important;
    padding: 20px !important;
    border-radius: 10px !important;
    background-color: aliceblue !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    min-height: 220px !important;
}

.dropzone-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid gray;
    border-radius: 10px;
    word-wrap: break-word;
    margin: 5px;
    padding: 15px;
    width: 200px;
}

.dropzone-item.selected {
    background-color: linen;
    border: 2px solid dodgerblue;
    box-shadow: 2px 2px 10px grey;
}

.dropzone-item img {
    margin-bottom: 10px;
    width: 50%;
}
