body {
    font-family: 'Source Sans Pro', sans-serif;
}

.dev{
    color: white;
    padding: 10px;
    font-weight: bold;
    font-size: 1.2em;
    animation: blink 0.5s;
    animation-iteration-count: 5;
    text-align: center;
}
@keyframes blink { 50% { background-color:yellow ; }  }

.ahs-background-oranje {
    background-color: #f58732;
}

.ahs-color-oranje {
    color: #f58732;
}

.application-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
}

.main-content {
    max-width: 1300px;
    padding: 20px;
    align-self: center;
    width: 100%;
}

.result-card {
    transition: transform .2s; /* Animation */
    cursor: pointer;
    z-index: 1;
    position: relative;
}
.result-card-list {
    transition: transform .2s; /* Animation */
    cursor: pointer;
    z-index: 1;
    position: relative;
    margin-bottom: 15px !important;
}

.result-card:hover {
    transform: scale(1.05);
    z-index: 10;
    box-shadow: 4px 4px 10px grey !important;
}
.result-card-list:hover {
    transform: scale(0.98);
    z-index: 10;
    box-shadow: 4px 4px 10px grey !important;
}

.icon-group-top-left {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
}

.icon-group-top-right {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
}

.icon-group-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
}

.icon-group-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
}

.banner-profielfoto {
    position: absolute;
    top: 10px;
    left: 10px;
    border: 1px solid white;
}

.result-card-icon {
    margin: 2px;
}

.icon-hoverable {
    opacity: 0.5 !important;
    filter: grayscale();
}

.icon-hoverable:hover {
    opacity: 1 !important;
    filter: none;
}

.carrousel {
    border-radius: 5px;
    width: 100%;
    padding: 20px;
}

.carrousel-content {
    display: flex;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    overflow-y: hidden;
    /*white-space: nowrap;*/
    -webkit-overflow-scrolling: touch; /* [3] */
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.carrousel-content img {
    height: 140px
}

.carrousel-content::-webkit-scrollbar {
    display: none;
}

.result-card-carrousel {
    position: relative;
    display: inline-block !important;
    margin: 3px;
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
}

/*.title-overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.73);
    font-size: 1.2em;
}*/

.title-overlay {
    width: 100%;
    padding: 10px;
    margin-top: -5px;
    font-weight: bold;
    height: 60px;
    background-color: white;
    border-top: 2px solid lightgrey;
}

.subtitle {
    color: gray;
    font-size: 0.9em;
}

.detail-view-header {
    height: 300px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    background-size: cover !important;
}

@media only screen and (max-width: 600px) {
    /* For desktop: */
    .home-search-overlay {
        position: absolute;
        bottom: 60px;
        margin-bottom: 0;
        left: 50vw;
        width: 96vw;
        margin-left: -48vw;
        font-size: 0.6em;
        padding: 10px;
        border-radius: 10px;
        background-color: white;
        box-shadow: 2px 2px 10px grey;
    }
}


@media only screen and (min-width: 600px) {
    /* For tablets: */
    .home-search-overlay {
        position: absolute;
        bottom: -50px;
        left: 50vw;
        width: 80vw;
        margin-left: -40vw;
        padding: 20px;
        font-size: 1em;
        border-radius: 15px;
        background-color: white;
        box-shadow: 3px 3px 10px grey;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .home-search-overlay {
        position: absolute;
        bottom: -50px;
        left: 50vw;
        width: 70vw;
        margin-left: -35vw;
        padding: 20px;
        border-radius: 25px;
        background-color: white;
        box-shadow: 4px 4px 10px grey;
    }
}

.detail-view-titel {
    position: absolute;
    border-radius: 5px;
    display: flex;
    align-items: center;
    line-height: 1.2;
    bottom: 10px;
    left: 10px;
    padding: 20px;
    font-weight: bold;
    font-size: 1.6em;
    background-color: rgba(255, 255, 255, 0.7);
}

.detail-view-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.63);
}

.detail-view-icons {
    position: absolute;
    border-radius: 5px;
    top: 10px;
    right: 10px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.3);
}

.detail-view-message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    border-radius: 5px;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    color: white;
    background-color: rgba(0, 0, 0, 0.58);
}

.detail-view-status {
    position: absolute;
    border-radius: 5px;
    bottom: 10px;
    right: 10px;
    padding: 5px;
}

.aside-metadata {
    background-color: #eff6fa;
}

.save-bar {
    background-color: #f58732 !important;
    position: fixed !important;
    bottom: 10px;
    right: 20px;
    width: 50%;
    min-width: 180px;
    border-radius: 10px !important;
    animation: save-bar-animation .5s;
    z-index: 1;
}

@keyframes save-bar-animation {
    from {
        bottom: -50px;
    }
    to {
        bottom: 10px;
    }
}

.embargo {
    background-color: aliceblue;
    padding: 30px;
    border-radius: 20px;
    margin: 20px 0;
    position: relative;
}

.embargo-message {
    position: absolute;
    top: 5px;
    left: 30px;
    color: red;
    animation: newEmbargoMessage 8s;
    opacity: 0;
}

@keyframes newEmbargoMessage {
    from {opacity: 1}
    90% {opacity: 0.8}
    to {opacity: 0; display: none;}
}

.content-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.footer a {
    color: white;
}

.input-field {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    outline: none !important;
}

.clickable-list-item{
    cursor: pointer;
}

.sharing:not(:last-child){
    margin-right: 10px;
    transition: transform .2s;
}

.sharing:hover{
    transform: scale(1.1);
}

.info-viewer{
    padding: 10px;
    background-color: rgba(170, 207, 232, 0.07);
}

.academiejaar{
    color: #555555;
}
