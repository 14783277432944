.dropzone, .dropzone * {
    box-sizing: border-box; }
.dropzone .dz-message {
    display: none; }

.dz-max-files-reached {
    pointer-events: none;
    cursor: default;
}

.dropzone .dz-file-preview {
    /*
    visibility: hidden;
    display: none; 
    */
}

/*
.dropzone .dz-file-preview {
    display: none !important;
}

#DropzoneField .myFolderPreview {
    color: teal;
}
.dz-folder-preview {
    font-size: larger;
    border: solid black 1px !important;
}

.HiddenContainer {
    display: none;
}
*/
/*.dropzone {
    display: flex;
}*/

/*.dropzone .dz-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5em; }
.dropzone .dz-preview .dz-progress {
    display: block;
    width: 200px;
    height: 15px;
    border: 1px solid #aaa; }
.dropzone .dz-preview .dz-progress .dz-upload {
    height: 100%;
    width: 50%;
    background: green; }
.dropzone .dz-preview .dz-details {
    display: flex;
}
.dropzone .dz-preview .dz-error-message {
    color: red;
    display: none; }
.dropzone .dz-preview.dz-error .dz-error-message, .dropzone .dz-preview.dz-error .dz-error-mark {}
.dropzone .dz-preview .dz-error-mark, .dropzone .dz-preview .dz-success-mark {
    opacity: 0;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
    opacity: 1;
}
.dropzone .dz-complete{
    display: none;
}

.dropzone .dz-details{
    display: flex;
    align-items: center;
}
.dropzone .dz-filename{
    font-weight: bold;
}
.dropzone .dz-tube{
    margin-left: 10px;
    margin-right: 10px;
}
.dropzone .dz-thumbnail{
    height: 50px;
}
.dropzone .dz-info-group{
    display: flex;
    align-items: center;
}



.dropzone.dz-started .dz-message {
    display: none; }
.dropzone.dz-drag-hover .dz-message {
    opacity: 0.5; }

.dropzone .dz-message {
    display: none;
    text-align: center;
    margin: 2em 0; }
.dropzone .dz-preview.dz-file-preview .dz-details {
    opacity: 1; }
.dropzone .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear; }
.dropzone .dz-preview:hover .dz-details {
    opacity: 1; }
.dropzone .dz-preview .dz-details .dz-filename {
    white-space: nowrap; }
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis; }
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent; }

.dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;}
!*.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;}*!
.dropzone .dz-preview.dz-processing .dz-progress {
    opacity: 1;}
.dropzone .dz-preview.dz-complete .dz-progress {
    opacity: 0;}
.dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;}
.dropzone .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: linear-gradient(to bottom, #666, #444);
    width: 0;}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto; }
.dropzone .dz-preview .dz-error-message {
    pointer-events: none;
    display: none;
    opacity: 0;}
.dropzone .dz-preview .dz-error-message:after {
    content: '';}*/
